@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Slate by Pixelarity
	pixelarity.com @pixelarity
	License: pixelarity.com/license
*/

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		border: solid 1px _palette(border) !important;

		&.special {
			border: 0 !important;
		}
	}

/* Header */

	#header {
		border-bottom: solid 1px _palette(border);
	}

	.dropotron {
		border: solid 1px _palette(border);
	}

/* Banner */

	#banner {
		.indicators {
			li {
				&:before {
					background: #000;
				}
			}
		}

		> article {
			overflow: hidden;

			&:after {
				display: none;
			}

			img {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}